<template >
  <div>
    <Contato />
  </div>
</template>

<script>
import Contato from "../components/contato/contato.vue";
export default {
  components: {
    Contato,
  },
};
</script>
<style scoped>
</style>