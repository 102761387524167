<template>
  <div style="">
    <TopMenu />
    <div class="w-100" style="margin-top: 20vh">

      <!-- Tópicos -->
      <div class="col mt-5 tracking-in-contract-bck">
        <div class="row text-center" id="body">
          <div class="col mt-2">
            <h5 style="color: #f54308">A MG Assessoria e Cobrança</h5>
            <p>
              <i class="bi bi-telephone" style="color: #f54308"></i> (35)
              3731-4343
            </p>           
            <p @click="whatsapp" class="whatsapp" style="cursor:pointer">
              <i class="bi bi-whatsapp" style="color: #f54308"></i> (35)
              35 9 9252-0777
            </p>
            <p>
              <i class="bi bi-geo-alt-fill" style="color: #f54308"></i> Av.
              Hemernegildo Donati, 15
            </p>
            <p>
              <i class="bi bi-geo-alt-fill" style="color: #f54308"></i> Segundo
              Andar
            </p>
            <p>
              <i class="bi bi-geo-alt-fill" style="color: #f54308"></i> Andradas
            </p>
            <p>
              <i class="bi bi-geo-alt-fill" style="color: #f54308"></i> Minas
              Gerais
            </p>
          </div>
          <div class="col mt-2">
            <h5 style="color: #f54308">Mídias Sociais</h5>
              <p class="whatsapp" @click="whatsapp">
              <i class="bi bi-whatsapp" style="color: #f54308;font-size:28px;"></i> Whatsapp
            </p>
            <p class="instagram" @click="instagram">
              <i class="bi bi-instagram" style="color: #f54308;font-size:28px;"></i> Instagram
            </p>
            <p class="facebook" @click="facebook">
              <i class="bi bi-facebook" style="color: #f54308;font-size:28px;"></i> Facebook
            </p>
            <p class="linkedin" @click="linkedin">
              <i class="bi bi-linkedin" style="color: #f54308;font-size:28px;"></i> Linkedin
            </p>
            <!-- <p class="twitter">
              <i class="bi bi-twitter" style="color: #f54308"></i> Twitter
            </p> -->
          </div>
        </div>
      </div>
      <!-- Tópicos -->

      <div class="container mt-5 tracking-in-contract-bck">
        <div class="col">
          <div class="col">
            <div class="col text-center">
              <p>
                Nôs encontre aqui: visitar pelo
                <b class="b-googlemaps" style="color: #f54308" @click="googleMaps"
                  >GOOGLE MAPS</b
                >
              </p>

              <p>
                Contato pelo email:
                <span class="span-email">
                  mgcobranca<b>@mgcobranca</b>.com.br
                </span>
              </p>
            </div>
          </div>
          <div class="col text-center">
            <div>
              <iframe
              class="ifram-maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4457.951918943718!2d-46.58054905353826!3d-22.07605699602113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c9bc6444ef7c57%3A0x56cd3c6994a19078!2sAv.%20Hemernegildo%20Donati%2C%2015%20-%20Andradas%2C%20MG%2C%2037795-000!5e1!3m2!1spt-BR!2sbr!4v1646077550997!5m2!1spt-BR!2sbr"
                width="600"
                height="250"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopMenu from "../menu/topMenu.vue";
export default {
  name: "Contato",
  data() {
    return {};
  },
  methods: {
    googleMaps() {
      window.open(
        "https://www.google.com.br/maps/place/Av.+Hemernegildo+Donati,+15+-+Andradas,+MG,+37795-000/@-22.0759515,-46.5810616,19z/data=!3m1!4b1!4m5!3m4!1s0x94c9bc644504dc8f:0xabd6030d78db1412!8m2!3d-22.0759527!4d-46.5805144"
      );
    },
    instagram(){
       window.open('https://www.instagram.com/mgcobrancaandradas/', '_blank');
    },
    facebook(){
       window.open('https://www.facebook.com/mgcobrancaandradas/', '_blank');
    },
     linkedin(){
       window.open('https://www.linkedin.com/in/mg-cobran%C3%A7a-751b191b5/', '_blank');
    },
    whatsapp(){
      window.open('https://api.whatsapp.com/send?phone=5535992520777&text=Olá tenho uma pergunta.', '_blank');
}
  },
  components: {
    TopMenu,
  },
};
</script>


<style scoped>
.tracking-in-contract-bck {
  -webkit-animation: tracking-in-contract-bck 3s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract-bck 3s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
}
.twitter,
.facebook,
.linkedin,
.instagram,
.whatsapp {
  font-size: 16px;
  transition: all ease 0.5;
}
.twitter:hover,
.facebook:hover,
.linkedin:hover,
.instagram:hover,
.whatsapp:hover {
  transition: all ease 0.5s;
  font-size: 18px;
  cursor: pointer;
}
.span-email {
  color: #f54308;
  font-size: 16px;
  transition: all ease 0.5;
}
.span-email:hover {
  transition: all ease 0.5s;
  font-size: 18px;
  cursor: pointer;
}
.b-googlemaps {
  font-size: 16px;
  transition: all ease 0.5;
}
.b-googlemaps:hover {
  transition: all ease 0.5s;
  font-size: 18px;
  cursor: pointer;
}
.div-row {
  display: flex;
  flex-direction: row;
}

@-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@media (max-width: 767.98px) {
  #body {
    display: flex;
    flex-direction: column;
  }

  .ifram-maps{
   width: 100%;
   height: auto;
  }
}
</style>